@font-face {
  font-family: "Gilroy Medium";
  src: url('./fonts/Gilroy-Medium-2.otf');
}

@font-face {
  font-family: "Gilroy";
  src: url('./fonts/gilroy-regular-3.otf');
}

@font-face {
  font-family: "Gilroy Bold";
  src: url('./fonts/gilroy-bold-4.otf');
}

@font-face {
  font-family: "Gilroy Extra Bold";
  src: url('./fonts/Gilroy-ExtraBold.otf');
}

html,body {
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
  font-family: 'Gilroy';
  background: #000;
  font-weight: 400;
}

a{
  outline: none !important;
}
a:focus, a:visited, a:link, a:active {
  outline: none !important;
  outline-style: none !important;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 3px !important;
  height: 5px !important; 
  background-color: rgba(32,32,32,0.8) !important;
}

::-webkit-scrollbar-thumb {
  background-color: #202020 !important;
  background-clip: padding-box;
  border-radius: 0;
}
::-webkit-scrollbar-thumb :hover {
  background-color: rgba(32,32,32,0.5);
}
      

@tailwind base;
@tailwind components;
@tailwind utilities;